import React from 'react';
import Box from '../../assets/screens/box.svg';
import BoxSent from '../../assets/screens/box2.svg';
import emailservice from '../../api/emailservice';
import './style.scss';

const validNameRegex = RegExp(
  /^[A-zÁ-ź .ñ']+$/,
);

const validEmailRegex = RegExp(
  /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/,
);

const validPhoneRegex = RegExp(
  /^[0-9( )\-+]+$/,
);

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: { text: 'name', value: '', error: null },
        phone: { text: 'phone', value: '', error: null },
        email: { text: 'email', value: '', error: null },
      },
      error: null,
      sentForm: false,
    };
    this.form = React.createRef();
    this.formBtn = React.createRef();
    this.contImgOk = React.createRef();
    this.box = React.createRef();
  }

  cleanForm = () => {
    this.setState({
      form: {
        name: { text: 'name', value: '', error: null },
        phone: { text: 'phone', value: '', error: null },
        email: { text: 'email', value: '', error: null },
      },
    });
  }

  getImgForm = () => {
    const { sentForm } = this.state;
    return sentForm ? BoxSent : Box;
  }

  checkErrorForm = (name, value) => {
    let error;

    switch (name) {
      case 'name':
        error = validNameRegex.test(value)
          ? null
          : 'Full name is not valid!';
        break;
      case 'phone':
        error = validPhoneRegex.test(value)
          ? null
          : 'Phone number is not valid!';
        break;
      case 'email':
        error = validEmailRegex.test(value)
          ? null
          : 'Email is not valid!';
        break;
      default:
        break;
    }

    this.setState(state => ({
      form: { ...state.form, [name]: { text: name, value, error } },
    }));

    return error ? 1 : 0;
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.checkErrorForm(name, value);
  }

  checkFormErros = form => {
    let errors = 0;
    Object.values(form).forEach(e => {
      errors += this.checkErrorForm(e.text, e.value);
    });

    return errors;
  }

  renderFormText = () => {
    const { sentForm } = this.state;
    if (sentForm) {
      return (
        <h2>
          Thank you for applying!
          <br />
          We will contact you soon.
        </h2>
      );
    }

    return (
      <h2>
        We need drivers like you!
        <br />
        Let’s work together!
      </h2>
    );
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.formBtn.current.disabled = true;
    const { form } = this.state;
    const { name, phone, email } = form;
    const errors = await this.checkFormErros(form);

    if (errors === 0) {
      emailservice.post('api/sendMail', {
        to: 'careers@xddelivery.com',
        company_name: 'Expert Delivery LLC',
        name: name.value,
        phone_number: phone.value,
        email: email.value,
        secret_key: process.env.REACT_APP_SERVICE_EMAIL_API,
      })
        .then(res => {
          if (res.data === 'Email sent') {
            this.cleanForm();
            this.box.current.style.transform = 'scale(0)';
            setTimeout(() => {
              this.setState({ sentForm: true });
              this.box.current.style.transform = 'scale(1)';
            }, 500);
            this.formBtn.current.disabled = false;
          } else {
            this.setState({ error: res.data });
          }
        })
        .catch(() => {
          this.setState({ error: 'Something went wrong!' });
        });
    } else {
      this.formBtn.current.disabled = false;
    }
  }

  render() {
    const { form, error } = this.state;
    const { name, phone, email } = form;

    return (
      <section id="form" className="form">
        <form onSubmit={this.handleSubmit} ref={this.form}>
          {this.renderFormText()}
          <input
            name="name"
            type="text"
            className={name.error ? 'bad-input' : ''}
            value={name.value}
            onChange={this.handleChange}
            placeholder="Full name:"
          />
          {name.error ? <span>{name.error}</span> : ''}
          <input
            name="phone"
            type="tel"
            className={phone.error ? 'bad-input' : ''}
            value={phone.value}
            onChange={this.handleChange}
            placeholder="Phone number:"
          />
          {phone.error ? <span>{phone.error}</span> : ''}
          <input
            name="email"
            type="email"
            className={email.error ? 'bad-input' : ''}
            value={email.value}
            onChange={this.handleChange}
            placeholder="Email:"
          />
          {email.error ? <span>{email.error}</span> : ''}
          {error ? <span>{error}</span> : ''}
          <button type="submit" ref={this.formBtn}>Join Us!</button>
        </form>
        <img src={this.getImgForm()} alt="box" ref={this.box} />
      </section>
    );
  }
}

export default Form;
