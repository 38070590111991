import React from 'react';
import BenefitsImg from '../../assets/screens/benefits.svg';
import './style.scss';

const Benefits = () => (
  <section className="benefits">
    <article>
      <h2>Our Benefits</h2>
      <ul>
        <li>Starting Rate $15.00 per hour</li>
        <li>Health Insurance</li>
        <li>Paid Time Off</li>
        <li>Referral Program</li>
        <li>Paid Performance Incentives</li>
      </ul>
      <h3>The best place for drivers like you!</h3>
    </article>
    <div className="img-container">
      <img src={BenefitsImg} alt="Benefits" />
    </div>
  </section>
);

export default Benefits;
