import React from 'react';
import Logo from '../../assets/main/logo.svg';
import Position from '../../assets/icons/position.svg';
import ScrollBtn from '../ScrollBtn';
import './style.scss';

const Welcome = () => (
  <section className="welcome">
    <header>
      <img src={Logo} alt="Expert Delivery Logo" />
      <a href="https://goo.gl/maps/iBjN36TVY7WDBqQA6" target="_new">
        <img src={Position} alt="map position" />
        Irvine, CA
      </a>
    </header>
    <div>
      <article>
        <h1>Looking for a delivery driver job?</h1>
        <ScrollBtn />
      </article>
      <div className="city-background">
        <div className="animation-container" />
        <div className="animation-container2" />
      </div>
    </div>
  </section>
);

export default Welcome;
