import React from 'react';

const ScrollBtn = () => {
  const scroll = () => {
    document.getElementById('form')
      .scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <button onClick={scroll} type="button">Join Us!</button>
  );
};

export default ScrollBtn;
