import React from 'react';
import Welcome from './welcome';
import About from './about';
import Benefits from './benefits';
import Requirements from './requirements';
import Form from './form';
import Footer from './footer';

const App = () => (
  <>
    <Welcome />
    <About />
    <Benefits />
    <Requirements />
    <Form />
    <Footer />
  </>
);

export default App;
