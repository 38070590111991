import React from 'react';
import Position from '../../assets/icons/position2.svg';
import Email from '../../assets/icons/email.svg';
import Logo from '../../assets/main/logo_cyber.svg';
import './style.scss';

const Footer = () => (
  <footer className="footer">
    <section>
      <a target="_new" href="https://goo.gl/maps/dqeg5z66on5cgANQ8">
        <article className="links-container">
          <img src={Position} alt="position" />
          <p>
            <span>Expert Delivery LLC</span>
            <br />
            Irvine, California,
            <br />
            California, United States
          </p>
        </article>
      </a>
      <a href="mailto:careers@xddelivery.com">
        <article className="links-container">
          <img src={Email} alt="position" />
          <p>careers@xddelivery.com</p>
        </article>
      </a>
    </section>
    <div>
      <p>
        2020 Expert Delivery LLC, powered by
      </p>
      <a target="_new" href="https://cyberhelpsolutions.com/">
        <img src={Logo} alt="logo" />
      </a>
    </div>
  </footer>
);

export default Footer;
