import React from 'react';
import Van from '../../assets/screens/van.svg';
import './style.scss';

const About = () => (
  <section className="about">
    <div className="img-container">
      <img src={Van} alt="Van" />
    </div>
    <article>
      <h2>About us</h2>
      <p>
        We are a hardworking company responsible for
        the delivery of Amazon packages in the Irvine
        area
      </p>
      <h3>We need drivers like you!</h3>
    </article>
  </section>
);

export default About;
