import React from 'react';
import RequirementsImg from '../../assets/screens/requirements.svg';
import './style.scss';

const Requirements = () => (
  <section className="requirements">
    <div className="img-container">
      <img src={RequirementsImg} alt="Benefits" />
    </div>
    <article>
      <h2>Requirements</h2>
      <ul className="margin-bottom">
        <li>Must be 21+ years older</li>
        <li>Must have a valid driver’s license</li>
        <li>Good interpersonal communication skills</li>
        <li>Previous driving and delivery experience preferred</li>
        <li>Ability to handle physical workload </li>
      </ul>
      <h2>Essential Duties and Responsibilities</h2>
      <ul>
        <li>Properly load and protect all the product for delivery</li>
        <li>Safely drive and operate your delivery vehicle at all times</li>
        <li>Ensure customer sastisfaction by following a delivery schedule and a professional attitude</li>
        <li>Lift packages up to 50 lbs</li>
        <li>Provide an excellent customer service</li>
      </ul>
    </article>
  </section>
);

export default Requirements;
